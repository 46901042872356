.web{
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768.99px){
    .web{
        display: none;
    }
}