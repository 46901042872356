@font-face {
  font-family: "PT Sans Caption";
  src: url("PTSans-Caption.woff2") format("woff2"),
    url("PTSans-Caption.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PT Sans Caption Bold";
  src: url("PTSans-CaptionBold.woff2") format("woff2"),
    url("PTSans-CaptionBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-Regular.woff2") format("woff2"),
    url("OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("Montserrat-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("Inter-Regular.woff2") format("woff2"),
    url("Inter-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

*,
*:focus,
*:hover {
  outline: none;
}

h1,
input {
  font-family: "Open Sans", sans-serif;
}

p,
button {
  font-family: "PT Sans Caption", sans-serif;
}

body {
  font-family: "PT Sans Caption", sans-serif;
  margin: 0;
  padding: 0;
  background: #ffffff;
}

/**/
#image_header {
  background: no-repeat url("../img/background_clips_2.png");
  background-size: 100% 101px;
  height: 100px;
  /*width: 460px;*/
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
}

#clip {
  background: no-repeat url("../img/clip_backgroup.png");
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
}

#astronaut {
  background: no-repeat url("../img/Amstrong.png");
  /*background-size: contain;*/
  background-size: 100% 101px;
  width: 50%;
  max-width: 132px;
  height: 100%;
}

#black_hole {
  background: no-repeat url("../img/blackhole.png");
  background-size: contain;
  width: 50%;
  max-width: 132px;
  /*transform: scaleX(-1);*/
  height: 100%;

  transition: 1s;
}

#image_header:hover #black_hole {
  transform: scale(1.1); /* Увеличиваем масштаб */
}

/**/

#header_img {
  width: 120px;
}

#right_open_menu_id {
  display: none;
}

.dropdown_div {
  position: relative;
  width: 100%;
}

.dropdown_col_6 {
  width: 100%;
  margin: 0;
  display: inline;
  background: #e6e9ed;
  position: absolute;
  z-index: 999;
  margin-left: -49%;
}

.dropdown_col_4 {
  width: 100%;
  margin: 0;
  display: inline;
  background: #e6e9ed;
  position: absolute;
  z-index: 999;
  border: 1px solid #434a54;
  border-radius: 5px;
  margin-left: -49.5%;
}

.dropdown_col_12 {
  width: 100%;
  margin: 0;
  display: none;
  background: #e6e9ed;
  position: absolute;
  z-index: 999;
  margin-left: -50%;
  border: 1px solid #434a54;
  border-radius: 5px;
}

.buttons {
  padding: 0;
  margin: 0 20px;
  border-radius: 5px;
  border: solid 0 transparent;
  width: 200px;
  height: 60px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
}

.max_200 {
  max-width: 200px;
  width: 100%;
}

.right_button {
  margin-left: auto;
  margin-right: 20px;
}

.buttons_div {
  margin: 0;
  padding: 30px 0 0 0;
}

#select_education {
  background: #a0d468;
}

#registration_button {
  background: #4fc1e9;
}

#cancel_button {
  background: #ffce54;
}

.container {
  margin: 0 auto;
}

.header_fixed {
  z-index: 9;
  /* position: fixed; */
  position: absolute;
  max-width: 1280px;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.header_text {
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  color: #434a54;
}

.header_small_text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  text-indent: 20px;
  margin: 2px 0 0 0;
  color: rgba(67, 74, 84, 0.5);
}

.header {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: space-between;
}
.header_menu_select {
  cursor: pointer;
  position: relative;
}

.header_menu {
  display: flex;
  justify-content: space-between;
}

.user_move {
  display: flex;
}

.user_data {
  padding: 0 12px 0 0;
}

.main_profile {
  display: flex;
  align-items: center;
}

.header_menu p:first-child {
  padding: 0 70px 0 0;
}

#user_fio {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  color: #434a54;
  margin: 6px 0 0 0;
  padding: 0 0 0 0;
  text-align: right;
}

.user_image {
  margin: auto 0;
  width: 80px;
  height: 80px;
}

.avatar_header {
  width: 80px;
  height: 80px;
}

.select_bar {
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 14px;
}

.left_select_bar {
  justify-content: flex-end;
}

.left_select_bar p:first-child {
  padding-right: 1em;
}

/*.select_bar p:nth-child(2n){*/
/*padding: 0 100px 0 100px;*/
/*}*/

.main {
  min-height: calc(100vh - 101px);
  text-align: center;
  max-width: 850px;
  margin: 0 auto;
}

.main_without_border {
  border-left: none;
  border-right: none;
}

.title_greetings {
  margin: 0;
  padding: 50px 0 0 0;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;

  letter-spacing: 0.25em;
  text-transform: uppercase;

  color: #434a54;
}

.modal_invite_p {
  margin: 0 0 20px 0;
  padding: 0;
  font-size: 14px;
  color: rgba(51, 51, 51, 0.5);
}

.modal_invite_span {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 70px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #000000;
}

.greetings {
  margin: 0;
  padding: 155px 0 0 0;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  text-align: center;
  text-transform: uppercase;

  color: #000000;
}

.link {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #4fc1e9;
  text-decoration: none;
  padding-top: 10px;
}

.link_pass {
  margin: 0 20px 0 0;
  padding: 40px 0 0 0;
}

#auth_button {
  background: #4fc1e9;
  width: 574px;
  margin: 0 0 0 0;
}

.step_reg {
  /*position: absolute;*/
  /*top: 95%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/

  /*display: flex;*/
  /*flex-direction: column;*/
  /*margin: 0 auto;*/

  position: sticky;
  top: 95%;
}

.input {
  padding: 0;
  margin: 30px 6px 0 6px;
  width: 276px;
  height: 60px;
  background: #e6e9ed;
  border-radius: 5px;
  border: 0 solid transparent;
  font-size: 14px;
  text-indent: 20px;
  font-style: normal;
  font-weight: normal;
}

.input_test {
  width: 100%;
  height: 60px;
  background: #e6e9ed;
  border-radius: 5px;
  border: 1px solid transparent;
  font-size: 14px;
  text-indent: 20px;
  font-style: normal;
  font-weight: normal;
  max-height: 58px;
  padding: 0;
}

.input_test_change {
  width: 100%;
  height: 58px;
  background: #f5f7fa;
  border-radius: 5px;
  border: 1px solid rgba(67, 74, 84, 0.2);
  font-size: 14px;
  text-indent: 20px;
  font-style: normal;
  font-weight: normal;
  max-height: 58px;
  padding: 0;
}

.max_input {
  width: 574px;
}

.max_input:nth-child(2n) {
  width: 574px;
  margin: 10px 0 0 0;
}

.password_input {
  background: url("../img/visibilityPass.png") no-repeat #e6e9ed center;
  background-position-x: 95%;
  background-size: auto 50%;
}

.form {
  margin: 30px 0 0 0;
}

.input_small {
  margin: 0 2px;
}

.input_middle {
  width: 418px;
  margin: 10px 2px;
}

.dropdown_small {
  margin: 2px;
}

.dropdown_big {
  margin: 10px 0;
  padding: 0;
  width: 846px;
}

.dropdown_middle {
  margin: 10px 0 0 0;
  padding: 0;
  width: 574px;
}

.button_big {
  width: 846px;
  margin: 10px 0;
}

.label_text {
  font-family: "PT Sans Caption", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #434a54;
}

.password_move {
  display: flex;
  justify-content: space-between;
  margin: 0 200px;
}

.small_text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin: 30px 0 20px 0;
  color: rgba(51, 51, 51, 0.5);

  border-radius: 5px;
}

.blue_text {
  color: #4fc1e9;
  text-decoration: none;
}

.blue_text:hover {
  text-decoration: underline;
}

.grey_text {
  color: #434a54;
  text-decoration: none;
}

.grey_text:hover {
  text-decoration: underline;
}

.avatar {
  width: 200px;
  min-width: 180px;
  height: 200px;
  border: 1px solid rgba(67, 74, 84, 0.2);
  border-radius: 5px;
  /*margin: 0 5px 0 5px;*/
  position: relative;
  overflow: hidden;
}

.avatar:hover .avatar_hover {
  display: flex;
}

.avatar_hover {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(67, 74, 84, 0.5);
  border-radius: 5px;
  display: none;
}

.avatar_hover > label {
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  margin: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.left_header {
  position: fixed;
  width: 215px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #1d1d39;
}

.right_header {
  position: fixed;
  width: 215px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5em;
  text-transform: uppercase;
  color: #1d1d39;
}

.left_header p {
  border-bottom: 1px solid rgba(67, 74, 84, 0.2);
  padding: 10px 0 10px 0;
  margin: 0;
}

.form_group {
  /*display: flex;*/
  /*flex: 0 0 auto;*/
  /*flex-flow: row wrap;*/
  /*align-items: center;*/
  /*margin-bottom: 0;*/
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
}

.form_row {
  /*width: 100%;*/
  /*display: flex;*/
  display: flex;
  flex-wrap: wrap;

  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

/*.form-row{*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    -ms-flex-wrap: wrap;*/
/*    flex-wrap: wrap;*/
/*    margin-right: -5px;*/
/*    margin-left: -5px;*/

/*}*/

/*.form_row > .col,*/
/*.form_row > [class*="col-"] {*/
/*    padding-right: 5px;*/
/*    padding-left: 5px;*/
/*}*/

.ml-5 {
  margin-left: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mr-5 {
  margin-right: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.table {
  width: 100%;
  border-spacing: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-indent: 20px;
  color: #ffffff;
}

.table_header {
  width: 100%;
  background: none;
  height: 60px;
  text-align: left;
}

.table_header th {
  background: #4fc1e9;
}

.test_col {
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
  background: red;
}

.table_header th:first-child {
  border-radius: 5px 0 0 0;
}

.table_header th:last-child {
  border-radius: 0 5px 0 0;
}

.table_main {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-indent: 20px;
  /*color: rgba(67, 74, 84, 0.2);*/
  color: black;
  text-align: left;
}

.table_main:hover {
  background: #e6e9ed;
  color: black;
  cursor: pointer;
}

.table_main_open {
  text-align: left;
  background: #e6e9ed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-indent: 20px;
  color: #434a54;
}

.table_main td {
  height: 60px;
}
.table_main_open td {
  height: 60px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-15 {
  padding-top: 15px;
}

.centered_image_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered_image_text_under {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text_title_olymp {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 5px;
}

.text_under_olymp {
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
}

@media (max-width: 1200px) {
  .container {
    max-width: 1000px;
    margin: 0 auto;
  }

  .header_fixed {
    max-width: 1000px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 990px;
    margin: 0 auto;
  }

  .header_fixed {
    max-width: 990px;
  }

  .select_bar p:nth-child(2n) {
    padding: 0 50px 0 50px;
  }
}

@media (max-width: 767px) {
  .user_image {
    width: 32px;
    height: 32px;
  }

  .avatar_header {
    width: 32px;
    height: 32px;
  }

  .user_data {
    display: none;
  }

  .select_bar {
    display: none;
  }

  .left_header {
    display: none;
  }

  .step_reg {
    display: none;
  }

  .pt-sm-100 {
    padding-top: 100px;
  }

  .container {
    /*max-width: 570px;*/
    width: auto;
    margin: 0 20%;
    /*margin: 0 auto;*/
  }

  .header_fixed {
    width: auto;
    /*margin: 0 20%;*/
    margin: 0;
  }

  .sm_button {
    width: 100%;
    margin: 0 auto;
  }

  .header {
    height: 60px;
    /*margin: 0 16px;*/
  }

  .title_greetings {
    padding-top: 12px;
    font-size: 10px;
  }

  .greetings {
    margin: 0 10px;
    padding-top: 20px;
    font-size: 24px;
  }

  .mobile_greetings {
    padding-top: 60px;
  }

  .password_move {
    display: flex;
    justify-content: space-around;
    margin: 0;
  }

  .link {
    margin: 0;
    padding-bottom: 0;
  }

  .link_pass {
    margin: 0;
  }

  .ml-sm-60 {
    margin-left: 60px !important;
  }

  .mr-sm-60 {
    margin-right: 60px !important;
  }

  .mb-sm-15 {
    margin-bottom: 15px;
  }

  #header_img {
    width: 108px;
  }

  .main {
    min-height: calc(100vh - 101px);
    text-align: center;
    max-width: none;
    margin: 0 auto;
    border-left: 0 solid transparent;
    border-right: 0 solid transparent;
  }
}

@media (max-width: 568px) {
  /*.container{*/
  /*    max-width: none;*/
  /*}*/

  /*.main{*/
  /*    height: calc(100vh - 101px);*/
  /*    text-align: center;*/
  /*    max-width: none;*/
  /*    margin: 0 auto;*/
  /*    border-left: none;*/
  /*    border-right: none;*/
  /*}*/

  .step_reg {
    display: none;
  }

  .pt-sm-100 {
    padding-top: 100px;
  }

  .container {
    /*max-width: 320px;*/
    width: auto;
    /*margin: 0 auto;*/
    margin: 0 10%;
  }

  .header_fixed {
    width: auto;
    /*margin: 0 10%;*/
    margin: 0;
  }

  .sm_button {
    width: 100%;
    margin: 0 auto;
  }

  .header {
    height: 60px;
    /*margin: 0 16px;*/
  }

  .title_greetings {
    padding-top: 12px;
  }

  .greetings {
    margin: 0 10px;
    padding-top: 20px;
  }

  .mobile_greetings {
    padding-top: 60px;
  }

  .password_move {
    display: flex;
    justify-content: space-around;
    margin: 0;
  }

  .link {
    margin: 0;
    padding-bottom: 0;
  }

  .link_pass {
    margin: 0;
  }

  .ml-sm-60 {
    margin-left: 60px !important;
  }

  .mr-sm-60 {
    margin-right: 60px !important;
  }

  .mb-sm-15 {
    margin-bottom: 15px;
  }

  #header_img {
    width: 108px;
  }

  .main {
    /*height: calc(100vh - 101px);*/
    height: auto;
    min-height: auto;
    text-align: center;
    max-width: none;
    margin: 0 auto;
    border-left: 0 solid transparent;
    border-right: 0 solid transparent;
  }
}

@media (min-width: 100px) {
  .mt-sm-5 {
    margin-top: 5px;
  }

  .mb-sm-5 {
    margin-bottom: 5px;
  }

  .ml-20 {
    margin-left: 0;
  }

  .mr-20 {
    margin-right: 0;
  }

  .mr-15 {
    margin-right: 0;
  }

  .ml-15 {
    margin-left: 0;
  }

  .ml-5 {
    margin-left: 5px;
  }

  .mr-5 {
    margin-right: 5px;
  }

  .image_div {
    flex-direction: column;
  }

  .avatar {
    margin: 0 auto;
  }

  .pt-30 {
    padding: 15px 0 0 0;
  }

  .acc_data {
    padding-top: 20px;
  }

  .load_image {
    padding: 0;
  }

  .content_margin {
    margin-top: 25px;
  }

  .ml-md-5 {
    margin-left: 0;
  }

  .mr-md-5 {
    margin-right: 0;
  }

  .sm-button-child {
    margin: 5px;
  }

  .child_margin {
    margin: 24px 0 0 0;
    padding: 0;
  }

  .table_header {
    text-indent: 0;
    text-align: center;
  }

  .table_main {
    text-indent: 0;
    text-align: center;
  }

  .text_title_olymp {
    font-size: 24px;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  .text_under_olymp {
    font-size: 12px;
    margin: 0 0 10px 0;
    padding: 0;
  }

  .button_start {
    display: flex;
    justify-content: space-around;
  }

  .max_200_left {
    text-decoration: none;
    color: white;
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }

  .max_200_right {
    text-decoration: none;
    color: white;
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }

  .mb-sm-10 {
    margin-bottom: 10px;
  }

  .ml-sm-10 {
    margin-left: 10px;
  }

  .mr-sm-10 {
    margin-right: 10px;
  }

  .link_flex {
    display: flex;
    justify-content: space-around;
  }

  .dropdown_menu {
    position: absolute;
    width: 100%;

    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;

    color: #1d1d39;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    z-index: 500;

    border-top: 4px solid transparent;
  }

  .dropdown_menu div {
    margin: 0;
    padding: 5px 12px 5px 0;
    border-bottom: 1px solid rgba(67, 74, 84, 0.2);
    max-width: 244px;
    min-width: 200px;
    background: #e6e9ed;
  }

  .dropdown_menu div p {
    margin: 0;
    padding: 0;
  }

  .right_menu {
    display: flex;
  }

  .select_bar {
    display: none;
  }

  .auth_reg {
    display: flex;
    font-size: 9px;
  }

  .auth_reg p {
    padding: 0 1em !important;
    margin: 0;
  }

  .right_open_menu {
    position: fixed;
    left: auto;
    right: 0;
    background: #e6e9ed;
    max-width: 250px;
    min-width: 240px;
    text-align: right;
    direction: rtl;
    text-indent: 10px;
    height: calc(100% - 61px);
    z-index: 999;

    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
  }

  .right_open_menu p {
    border-bottom: 1px solid rgba(67, 74, 84, 0.2);
    padding: 10px 0;
    margin: 0;
  }

  .pt_header {
    padding-top: 61px;
  }

  .mt_header {
    margin-top: 61px;
  }

  .header_img {
    height: 61px;
    width: auto;
  }

  #simple_modal_message p {
    text-align: center;
  }

  #simple_modal_message {
    min-width: 250px;
  }

  #modal_message {
    min-width: 250px;
  }

  #modal_message p {
    text-align: center;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    padding: 0;
    position: absolute;
    z-index: 1;

    border: 1px solid #434a54;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f5f7fa;
    color: #000000;

    font-size: 12px;
    text-align: center;

    width: 200px;
    bottom: 120%;
    left: 50%;
    margin-left: -100px; /* Use half of the width (120/2 = 60), to center the tooltip */
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .sm_none {
    display: none;
  }

  .sm_none_block {
    display: none;
  }

  .sm_none_flex {
    display: none;
  }

  .md_none {
    display: inline;
  }

  .md_none_block {
    display: block;
  }

  .md_none_flex {
    display: flex;
  }

  #opacity_panel {
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    opacity: 80%;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 49;
  }

  #opacity_panel_two {
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    opacity: 80%;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 49;
  }

  .text_modal_top {
    font-size: 24px;
  }

  .text_modal_under {
    font-size: 14px;
  }

  .invite_mobile {
    display: inline;
  }

  .invite_comp {
    display: none;
  }

  #image_header {
    display: none;
  }

  .question {
    bottom: 1%;
    right: 2%;
    width: 40px;
    height: 40px;
  }

  .table {
    padding-right: 0;
  }

  .loader {
    left: 45%;
  }

  .pl-5 {
    padding-left: 5px;
  }

  .pr-5 {
    padding-right: 5px;
  }

  .ml_account-15 {
    margin-left: 0;
  }

  .buttons_crop > button:nth-child(2) {
    margin-top: 15px !important;
  }

  .crop_modal > h1 {
    font-size: 24px;
  }

  .crop_modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: auto;
  }

  .footer {
    flex-direction: column;
  }

  .footer_contact_data {
    display: flex;
    flex-direction: column;
  }

  .footer_contact_data > a:last-of-type {
    padding: 0;
  }

  .footer_img {
    display: flex;
    width: 100%;
  }

  .footer_img > div {
    margin: 0 auto;
  }

  .footer p {
    text-align: center;
    margin: 10px 0;
  }

  .footer a:first-of-type {
    margin-top: 10px;
  }

  .footer a {
    text-align: center;
    text-decoration: none;
    color: black;
    margin: 0 0 10px 0;
    padding: 0;
  }

  .footer_right,
  .footer_left {
    margin: 0;
  }

  .main_gif_margin {
    margin: 20px 0;
  }

  .col-sm-1 {
    position: relative;
    width: 100%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    position: relative;
    width: 100%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    position: relative;
    width: 100%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    position: relative;
    width: 100%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    position: relative;
    width: 100%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    position: relative;
    width: 100%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    position: relative;
    width: 100%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    position: relative;
    width: 100%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    position: relative;
    width: 100%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    position: relative;
    width: 100%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    position: relative;
    width: 100%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .account_wrap {
    flex-wrap: wrap;
  }

  .account_shrink {
    flex-shrink: 0;
  }
}

@media (min-width: 768px) {
  .auth_reg {
    font-size: 14px;
  }

  .table_header {
    text-indent: 20px;
    text-align: left;
  }

  .table_main {
    text-indent: 20px;
    text-align: left;
  }

  .child_margin {
    margin: 90px 0 0 0;
    padding: 0;
  }

  .mt-sm-5 {
    margin-top: 0;
  }

  .mb-sm-5 {
    margin-bottom: 0;
  }

  .mr-20 {
    margin-right: 20px;
  }

  .mr-15 {
    margin-right: 15px;
  }

  .ml-15 {
    margin-left: 15px;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .left_header {
    display: none;
  }

  .image_div {
    flex-direction: column;
  }

  .mt_215 {
    /*margin: 0 auto;*/
    margin: 0 3%;
  }

  .main {
    border: none;
    max-width: 950px;
  }

  .acc_data {
    padding-top: 20px;
  }

  .pb-md-10 {
    padding-bottom: 10px;
  }

  .ml-md-10 {
    margin-left: 10px;
  }

  .mr-md-10 {
    margin-right: 10px;
  }

  .mt-md-10 {
    margin-top: 10px;
  }

  .ml-md-145 {
    margin-left: 145px !important;
  }

  .mr-md-145 {
    margin-right: 145px !important;
  }

  .avatar {
    margin: 0 auto;
  }

  .content_margin {
    margin-top: 25px;
  }

  .pt-30 {
    padding: 15px 0 0 0;
  }

  .load_image {
    padding: 0;
  }

  .text_title_olymp {
    font-size: 36px;
    margin: 0;
    padding: 0;
  }

  .text_under_olymp {
    font-size: 14px;
    margin: 10px 0 0 0;
    padding: 0;
  }

  .button_start {
    display: flex;
    justify-content: flex-end;
  }

  .max_200_left {
    text-decoration: none;
    color: white;
    padding: 0;
    margin: 0 20px 0 0;
    width: 100%;
  }

  .max_200_right {
    text-decoration: none;
    color: white;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .link_flex {
    display: flex;
    justify-content: space-around;
  }

  #forgot_pass {
    margin-left: 20px;
  }

  .mb-sm-10 {
    margin-bottom: 0;
  }

  .ml-sm-10 {
    margin-left: 0;
  }

  .mr-sm-10 {
    margin-right: 0;
  }

  .ml-md-5 {
    margin-left: 5px;
  }

  .mr-md-5 {
    margin-right: 5px;
  }

  .mb-md-10 {
    margin-bottom: 10px;
  }

  .dropdown_menu {
    position: absolute;
    width: 100%;

    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;

    color: #1d1d39;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    border: none;
  }

  .dropdown_menu div {
    margin: 0;
    padding: 10px 12px 10px 0;
    border-bottom: 1px solid rgba(67, 74, 84, 0.2);
    width: 80%;
    background: #e6e9ed;
  }

  .dropdown_menu div p {
    margin: 0;
    padding: 0;
  }

  .pt_header {
    padding-top: 101px;
  }

  .mt_header {
    margin-top: 101px;
  }

  .header_img {
    height: 101px;
    width: auto;
  }

  #simple_modal_message {
    min-width: 640px;
    max-width: 640px;
  }

  #modal_message {
    min-width: 640px;
    max-width: 640px;
  }

  .sm_none {
    display: inline;
  }

  .sm_none_block {
    display: block;
  }

  .sm_none_flex {
    display: flex;
  }

  .md_none_flex {
    display: none;
  }

  .md_none {
    display: none;
  }

  .md_none_block {
    display: none;
  }

  .text_modal_top {
    font-size: 36px;
  }

  .text_modal_under {
    font-size: 14px;
  }

  .invite_mobile {
    display: none;
  }

  .invite_comp {
    display: inline;
  }

  #black_hole {
    display: none;
  }

  #image_header {
    display: flex;
  }

  #header_img {
    width: 120px;
  }

  .question {
    bottom: 1%;
    right: 2%;
    width: 60px;
    height: 60px;
  }

  .table {
    padding-right: 40px;
  }

  .loader {
    left: 47%;
  }

  .pl-5 {
    padding-left: 0;
  }

  .pr-5 {
    padding-right: 0;
  }

  .ml_account-15 {
    margin-left: 15px;
  }

  .buttons_crop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .buttons_crop > button:nth-child(2) {
    margin-top: 0 !important;
  }

  .crop_modal > h1 {
    font-size: 36px;
  }

  .crop_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
  }

  .footer {
    flex-direction: row;
  }

  .footer_contact_data {
    display: flex;
    flex-direction: column;
  }

  .footer_contact_data > a:last-of-type {
    padding: 10px 0 20px 0;
  }

  .footer div {
    padding: 10px 5px 0 10px;
  }

  .footer div:first-of-type {
    padding: 10px 5px 0 0;
  }

  .footer div:last-of-type {
    padding: 10px 0 0 10px;
  }

  .footer_img {
    display: flex;
    width: auto;
    padding: 0 !important;
  }

  .footer_img > div {
    margin: 0;
  }

  .footer p {
    text-align: left;
  }

  .footer_contact_data > a {
    color: black;
    text-decoration: none;
    text-align: right;
  }

  .footer_right {
    margin-right: 15px;
  }

  .footer_left {
    margin-left: 15px;
  }

  .main_gif_margin {
    margin: 55px 0;
  }

  .col-md-1 {
    position: relative;
    width: 100%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    position: relative;
    width: 100%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    position: relative;
    width: 100%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    position: relative;
    width: 100%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    position: relative;
    width: 100%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    position: relative;
    width: 100%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    position: relative;
    width: 100%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    position: relative;
    width: 100%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    position: relative;
    width: 100%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    position: relative;
    width: 100%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    position: relative;
    width: 100%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .left_header {
    display: inline;
  }

  .mb-5 {
    margin-top: 5px;
  }

  .acc_data {
    padding-top: 20px;
  }

  .pt-30 {
    padding: 15px 0 0 0;
  }

  .load_image {
    padding: 0;
  }

  .mt_215 {
    margin: 0 165px;
  }

  .left_header p {
    width: 165px;
  }

  .image_div {
    flex-direction: column;
  }

  .avatar {
    margin: 0 auto;
  }

  .content_margin {
    margin-top: 25px;
  }

  .dropdown_menu {
    position: absolute;
    width: 100%;

    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;

    color: #1d1d39;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    z-index: 500;
    border: none;
  }

  .dropdown_menu div {
    margin: 0;
    padding: 10px;
    border-bottom: 1px solid rgba(67, 74, 84, 0.2);
    width: 70%;
    background: transparent;
    max-width: none;
    min-width: auto;
  }

  .dropdown_menu div p {
    margin: 0;
    padding: 0;
  }

  .right_menu {
    display: none;
  }

  .select_bar {
    display: flex;
  }

  .tooltip .tooltiptext {
    width: 300px;
    margin-left: -150px; /* Use half of the width (120/2 = 60), to center the tooltip */
  }

  #opacity_panel {
    display: none !important;
  }

  #opacity_panel_two {
    display: none !important;
  }

  #black_hole {
    display: inline;
  }

  #header_img {
    width: 120px;
  }

  .question {
    bottom: 1%;
    right: 1%;
    width: 60px;
    height: 60px;
  }

  .loader {
    left: 50%;
  }

  .col-lg-1 {
    position: relative;
    width: 100%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    position: relative;
    width: 100%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    position: relative;
    width: 100%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    position: relative;
    width: 100%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    position: relative;
    width: 100%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    position: relative;
    width: 100%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    position: relative;
    width: 100%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    position: relative;
    width: 100%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    position: relative;
    width: 100%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    position: relative;
    width: 100%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    position: relative;
    width: 100%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .mt_215 {
    margin: 0 215px;
  }

  .left_header p {
    width: 215px;
  }

  .acc_data {
    padding: 0;
  }

  .pt-30 {
    padding: 30px 0 0 0;
  }

  .image_div {
    flex-direction: row;
  }

  .avatar {
    margin: 5px 10px 0 20px;
  }

  .content_margin {
    margin-top: 90px;
  }

  .dropdown_menu {
    position: absolute;
    width: 100%;

    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;

    color: #1d1d39;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    z-index: 500;
    border: none;
  }

  .dropdown_menu div {
    margin: 0;
    padding: 10px;
    border-bottom: 1px solid rgba(67, 74, 84, 0.2);
    max-width: none;
    min-width: auto;
    width: 80%;
    background: transparent;
  }

  .dropdown_menu div p {
    margin: 0;
    padding: 0;
  }

  .question {
    bottom: 1%;
    right: 0.6%;
    width: 60px;
    height: 60px;
  }

  .ml_account-15 {
    margin-left: 0;
  }

  .col-xl-1 {
    position: relative;
    width: 100%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    position: relative;
    width: 100%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    position: relative;
    width: 100%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    position: relative;
    width: 100%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    position: relative;
    width: 100%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    position: relative;
    width: 100%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    position: relative;
    width: 100%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    position: relative;
    width: 100%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    position: relative;
    width: 100%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    position: relative;
    width: 100%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    position: relative;
    width: 100%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .account_wrap {
    flex-wrap: nowrap;
  }

  .account_shrink {
    flex-shrink: 1;
  }
}

.is-invalid {
  border: 1px solid red;
}

.disabled {
  background: rgb(230, 233, 237) !important;
  color: white !important;
  border: 0 solid transparent !important;
}

.button_green {
  cursor: pointer;
  background: #a0d468;
  color: white;
}

.button_green:hover {
  background: #8cc152;
  color: #434a54;
}

.button_green:active {
  background: #a0d468;
  color: #434a54;
  border: 1px solid #434a54;
}

.button_blue {
  cursor: pointer;
  background: #4fc1e9;
  color: white;
  text-transform: uppercase;
}

.button_blue:hover {
  background: #3bafda;
  color: #434a54;
  text-transform: uppercase;
}

.button_blue:active {
  background: #4fc1e9;
  color: #434a54;
  border: 1px solid #434a54;
}

.button_yellow {
  cursor: pointer;
  background: #ffce54;
  color: white;
  text-transform: uppercase;
}

.button_yellow:hover {
  background: #f6bb42;
  color: #434a54;
}

.button_yellow:active {
  background: #ffce54;
  color: #434a54;
  border: 1px solid #434a54;
}

.age_disabled {
  color: #757575;
}

.age_change {
  color: black;
}

.modal_title {
  text-transform: uppercase !important;
}

.elem_menu svg {
  display: none;
}

.elem_menu:hover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.elem_menu:hover .svg_elem_menu {
  display: inline;
}

.elem_left_menu svg {
  display: none;
  padding-right: 8px;
}

.elem_left_menu:hover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.elem_left_menu:hover .svg_elem_left_menu {
  display: inline;
}

.test_hover_max {
  transition: 1s; /* Время эффекта */
}

.text_title_olymp {
  transition: 1s;
}

.text_under_olymp {
  transition: 1s;
}

.test_hover_max:hover {
  transform: scale(1.1); /* Увеличиваем масштаб */
}

button {
  font-family: "PT Sans Caption", sans-serif;
}

#age {
  font-family: "PT Sans Caption", sans-serif;
}

.link {
  font-family: "PT Sans Caption", sans-serif;
  cursor: pointer;
}

.elem_left_menu {
  text-indent: 8px;
}

.input_test_change:focus {
  border: 1px solid #4fc1e9;
  outline: none;
}

.terms_and_privacy {
  font-family: "PT Sans Caption", sans-serif;
}

.top p {
  font-family: "PT Sans Caption", sans-serif;
}

#dropdown_children_list {
  margin-left: -50%;
}

@-moz-document url-prefix() {
  .dropdown_col_12 {
    margin-left: -100.3%;
    margin-top: 60px;
  }

  .dropdown_col_4 {
    margin-left: -101%;
    margin-top: 60px;
  }

  .dropdown_col_6 {
    margin-left: -101%;
    margin-top: 60px;
  }

  #dropdown_children_list {
    margin-left: -100.2%;
    margin-top: 60px;
  }
}

.question {
  cursor: pointer;
  position: fixed;

  transition: 1s;
}

.question:hover {
  transform: scale(1.2);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  /*background: transparent;*/
  /*bottom: 0;*/
  /*color: transparent;*/
  /*cursor: pointer;*/
  /*height: auto;*/
  /*left: 0;*/
  /*position: absolute;*/
  /*right: 0;*/
  /*top: 0;*/
  /*width: auto;*/
}

.main {
  padding-bottom: 100px;
}

.date_with_border {
  height: 100%;
}

.date_input_change_first {
  width: 100%;
  height: 58px;
  background: #f5f7fa;
  /*border-radius: 5px;*/
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border: 1px solid rgba(67, 74, 84, 0.2);
  border-right: none;
  font-size: 14px;
  text-indent: 20px;
  font-style: normal;
  font-weight: normal;
  max-height: 58px;
  padding: 0;
}

.date_input_change_last {
  width: 100%;
  height: 58px;
  background: #f5f7fa;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid rgba(67, 74, 84, 0.2);
  border-left: none !important;
  font-size: 14px;
  text-indent: 20px;
  font-style: normal;
  font-weight: normal;
  max-height: 58px;
  padding: 0;
}

.date_input_change_middle {
  width: 100%;
  height: 58px;
  background: #f5f7fa;
  border-radius: 0;
  border: 1px solid rgba(67, 74, 84, 0.2);
  font-size: 14px;
  text-indent: 20px;
  font-style: normal;
  font-weight: normal;
  max-height: 58px;
  padding: 0;
}
/*border: 1px solid #4FC1E9;*/
.date_input_change_middle:focus {
  outline: none;
}

.date_input_change_last:focus {
  outline: none;
}

.date_input_change_first:focus {
  outline: none;
}

#date_before {
  display: inline;
}

#date_after {
  display: none;
}

.child_dashboard_container {
  padding: 0 6px;
  width: 132px;
  display: flex;
  margin: 0 auto;
}

.img_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.img_container p {
  margin: 0;
}

.img_container:active {
  background: #e6e9ed;
}

.img_container:hover {
  background: #e6e9ed;
}

.img_container:hover .img_child {
  background: white;
}

.img_child {
  width: 60px;
  height: 60px;
  border-radius: 5px;
}

.child_dashboard_name {
}
/**/

.large-2 {
  float: left;
  width: 100%;
  background: transparent;
}

.large-2::-webkit-scrollbar-track {
  padding: 2px 0;
  background-color: #e6e9ed;
  border-radius: 5px;
}

.large-2::-webkit-scrollbar {
  width: 10px;
  height: 16px;
}

.large-2::-webkit-scrollbar-thumb {
  border-radius: 5px;
  width: 64px;
  background-color: #4fc1e9;
}

.splide__list {
  /*display: flex;*/
  /*justify-content: space-around;*/
  /*width: 100%;*/
  /*transform: none !important;*/
}

.splide__pagination {
  display: none !important;
}

.splide__arrow--next {
  position: absolute;
  top: 40%;
}

.splide__arrow--prev {
  position: absolute;
  top: 30%;
}

.splide {
  padding-bottom: 20px;
  padding-top: 20px;
}

.arrow_hover:hover {
  fill: black;
  fill-opacity: 1;
}

.arrow_hover:hover {
  fill: black;
  fill-opacity: 1;
}

.button_transparent {
  background: #f5f7fa;
  border: 1px solid rgba(67, 74, 84, 0.5);
  box-sizing: border-box;
  border-radius: 5px;
  color: rgba(29, 29, 57, 0.5);
}

.button_transparent:hover {
  color: rgba(29, 29, 57, 0.5);
  background: #f5f7fa;
  border: 1px solid #434a54;
  box-sizing: border-box;
  border-radius: 5px;
  color: #434a54;
}

.button_transparent:active {
  background: #4fc1e9;
  color: #434a54;
  border: 1px solid #434a54;
}

.button_level_select {
  background: #4fc1e9;
  border: none;
  color: #ffffff;
}

.game_text {
  margin: auto 0;
  font-size: 14px;
  font-family: "PT Sans Caption", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: left;
}

.line_game {
  width: 2px;
  height: 20px;
  background: rgba(67, 74, 84, 0.2);
  position: relative;
}

.line_game:nth-child(3):after {
  display: block;
  width: 5px;
  height: 2px;
  content: " ";
  background: rgba(67, 74, 84, 0.2);
  position: absolute;
  left: 50%;
  transform: translateX(-100%);
  bottom: -1px;
}

.first_line_game:after {
  display: block;
  width: 5px;
  height: 2px;
  content: " ";
  background: rgba(67, 74, 84, 0.2);
  position: absolute;
  left: 50%;
  transform: translate(-100%, -1000%);
  bottom: -1px;
}

.line_game_time {
  width: 2px;
  height: 54px;
  transform: translateY(16%);
  background: rgba(67, 74, 84, 0.2);
  z-index: -1;
}

.line_game_time_last {
  background: rgba(67, 74, 84, 0.2);
  z-index: -1;
  transform: translateY(23%);
  width: 2px;
  height: 36px;
  position: relative;
}

.line_game_time_last:after {
  position: absolute;
  left: 0;
  top: 100%;
  transform: translateX(-50%);
}

.line_game_time_last:nth-child(1):after {
  content: "0";
}

.line_game_time_last:nth-child(3):after {
  content: "2";
}

.line_game_time_last:nth-child(5):after {
  content: "4";
}

.line_game_time_last:nth-child(7):after {
  content: "6";
}

.line_game_time_last:nth-child(9):after {
  content: "8";
}

.line_game_time_last:nth-child(11):after {
  content: "10";
}

.svg_elem_right_menu {
  transform: rotate(180deg);
  display: none;
}

#user_selector {
  background: #e6e9ed;
}

#user_selector > p {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

#user_selector:hover .svg_elem_right_menu {
  display: inline;
}

.crop_container {
  position: relative;
  width: 100%;
  height: 250px;
  background: #333;
}

.main_relative {
  position: relative;
}

.crop_modal {
  transform: translate(-50%, -50%);
  background: #f5f7fa;
  mix-blend-mode: normal;
  border-radius: 5px;
  overflow: hidden;
  z-index: 51;
  max-width: 540px;
}

.crop_modal > h1 {
  margin: 15px 0 0 0;
  color: #000000;
  text-align: center;
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  font-family: "PT Sans Caption", sans-serif;
}

.crop_modal > div {
  margin: 30px 50px 10px 50px;
}

.crop_opacity {
  opacity: 80%;
  background: #000000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
}

.splide__list {
  display: flex;
  width: 100%;
}

.splide__slide {
  margin: 0 auto;
}

.footer p,
a {
  font-family: "PT Sans Caption", sans-serif;
}

.footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(67, 74, 84, 0.2);
}

.dropdown_buttons:focus > div > p {
  background: #e6e9ed;
}

#title_main_text {
  font-size: 14px;
  font-weight: normal;
  font-family: "PT Sans Caption", sans-serif;
  color: #434a54;
  line-height: 19px;
  text-align: left;
}

.dropdown_img {
  background: no-repeat url("../img/dropdownIcon.png") #e6e9ed center right 10px;
  background-size: auto;
}

.dropdown_img_active {
  background: no-repeat url("../img/dropdownIconActive.png") transparent center
    right 10px;
  background-size: auto;
}

.UIInputBlock:focus {
  outline: none !important;
  border: 1px solid #4fc1e9 !important;
}

.DashboardTable {
  text-align: left;
  width: 1100px !important;
}

.DashboardTable a {
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #15a0d0;
  text-decoration: none;
}

.DashboardTable tr,
.DashboardTable th,
.DashboardTable td {
  padding: 16px 14px !important;
  margin: 5px;
}

.DashboardTable .UIInputBlockBox {
  height: auto !important;
}

#DashboardTable-Group {
  width: 50px;
}

#DashboardTable-Patronomyc,
#DashboardTable-Surname {
  width: 150px;
}

#DashboardTable-Name {
  width: 100px;
}

.DashboardTable .UIInputBlock {
  padding: 8px 15px !important;
  height: 80% !important;
  width: 100% !important;
  margin: -14px;
}

.DashboardTeachersTableButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.DashboardInviteButton {
  border: none;
  background: transparent;
  cursor: pointer;
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #15a0d0;
}

.Teachers-PopupBG {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  background: rgba(15, 54, 68, 0.6);
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Teachers-inviteCard {
  padding: 25px 30px;
  z-index: 15;
  background: #f5f7fa;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.TeachersinviteCard__buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.TeachersinviteCard__buttonContainer button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.Teachers-inviteCard h3 {
  margin: 3px 0 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}

.Teachers-inviteCard p {
  margin: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #2e2e2e;
}

.Teachers-inviteCard .Teachers-inviteCode {
  margin: 24px 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 160%;
  text-align: center;
  color: #000000;
}

.Teachers-inviteCard .UIInputBlockBox {
  margin: 24px 0 40px !important;
}

.Teachers-inviteCard .UIInputBlockBox .UIInputBlock {
  margin: 0;
  width: 336px !important;
  height: 48px !important;
}

.Teachers-inviteCard .button-blue {
  padding: 10px 40px !important;
}

.DashboardInviteButton:hover {
  text-decoration: underline;
}

.popup-bg figure {
  margin: 16px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-green,
.button-green-trans,
.button-blue,
.button-red,
.button-red-trans,
.header a {
  -webkit-tap-highlight-color: transparent !important;
}

.button-green:hover {
  background: #b2e879 !important;
  border: solid 1px #b2e879 !important;
}

.button-green:active {
  background: #b2e879 !important;
  border: solid 1px #a0d468 !important;
}

.button-green-trans:hover {
  background: #d6e9b6 !important;
  border: solid 1px #d6e9b6 !important;
}

.button-green-trans:active {
  background: #d6e9b6 !important;
  border: solid 1px rgba(160, 212, 104) !important;
}

.button-red {
  background: #fe356b !important;
  border: solid 1px #fe356b !important;
}

.button-red:hover {
  background: #fc5d88 !important;
  border: solid 1px #fc5d88 !important;
}

.button-red:active {
  background: #fc5d88 !important;
  border: solid 1px #fe356b !important;
}

.button-red-trans {
  background: transparent !important;
  border: solid 1px #fe356b !important;
}

.button-red-trans:hover {
  background: #ffd7e1 !important;
  border: solid 1px #ffd7e1 !important;
}

.button-red-trans:active {
  background: #ffd7e1 !important;
  border: solid 1px #fe356b !important;
}

.button-blue:hover {
  background: rgba(105, 214, 252, 1) !important;
  border: solid 1px rgba(105, 214, 252, 1) !important;
}

.button-blue:active {
  background: rgba(105, 214, 252, 1) !important;
  border: solid 1px rgba(0, 189, 255, 1) !important;
}

.button-blue-index:hover {
  background: #80bbfe !important;
  border: solid 1px #80bbfe !important;
}

.button-blue-index:active {
  background: #80bbfe !important;
  border: solid 1px #6eaffa !important;
}

.button-green-index:hover {
  background: #61e280 !important;
  border: solid 1px #61e280 !important;
}

.button-green-index:active {
  background: #61e280 !important;
  border: solid 1px #39cc5c !important;
}

.hover-underline:hover {
  text-decoration: underline !important;
}

.unselectable {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.placeholder-fix-home-page::placeholder {
  color: #4e87db;
  opacity: 1;
}

.headerButton {
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.headerButton:hover {
  background-color: rgba(210, 228, 235, 0.5);
}

.headerButton:active {
  background-color: #d2e4eb;
}

.footer-box {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 72px 0 27px 0;
}

.footer-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 165px;
}

.footer-left a {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #0067de;
  text-decoration: none;
}

.footer-left a:hover,
.footer-tel:hover,
.footer-email:hover {
  text-decoration: underline;
}

.footer-logo {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.footer-left img {
  margin: 0 56px 0 0;
}

.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.footer-tel {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #110e09;
  text-decoration: none;
  margin-bottom: 16px;
}

.footer-email {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-decoration: none;
  color: #0067de;
}

.footer-social-box {
  margin-top: 20px;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.footer-social-box svg {
  margin-right: 37px;
}

#DirectorEduStep-Desktop {
  display: block;
}

#DirectorEduStep-Mobile {
  display: none;
}

.popup-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  background: rgba(15, 54, 68, 0.6);
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-card {
  cursor: default;
  background: #ffffff;
  border-radius: 16px;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-card p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 160%;
  text-align: center;
  color: #2e2e2e;
  margin: 0;
}

#restore-popup-button-bg {
  padding: 16px 56px;
  background: #a0d468;
  border-radius: 8px;
  border: 1px #a0d468 solid;
  cursor: pointer;
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-top: 32px;
}

.popup-card a {
  -webkit-tap-highlight-color: transparent !important;
}

.RegistrationTeacherCode-CardBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.RegistrationTeacherCodeCardBox-Card {
  background: #f5f7fa;
  border-radius: 16px;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  width: 400px;
  height: 300px;
  margin: 32px;
}

.RegistrationTeacherCodeCardBoxCard-Subflex {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.RegistrationTeacherCodeCardBoxCard-Subflex .button-blue {
  width: 184px;
  margin: 0 auto;
}

.RegistrationTeacherCodeCardBoxCard-Heading {
  margin: 0 0 32px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
}

.RegistrationTeacherCodeCardBoxCard-Summary {
  text-align: left;
  margin: 0;
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #2e2e2e;
}

#TeacherCode-Popup {
  max-width: 496px;
}

#TeacherCode-Popup,
#ParentCode-Popup {
  padding: 48px 40px 40px;
}

#TeacherCode-Popup h1,
#ParentCode-Popup h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
  margin-bottom: 16px;
}

#TeacherCode-Popup p,
#ParentCode-Popup p {
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #2e2e2e;
}

#TeacherCodePopupButton-Desktop {
  display: block;
}

#TeacherCodePopupButton-Mobile {
  display: none;
}

.OnlyMobile {
  display: none;
}

.OnlyDesktop {
  display: block;
}

#AvatarDelete-Popup {
  padding: 40px 48px;
}

#AvatarDelete-Popup h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  color: #000000;
  margin-bottom: 40px;
}

.DeletePopupButtonBox {
  display: flex;
}

.DeletePopupButtonBox button {
  padding: 10px 50px;
  border-radius: 8px;
  margin: 0 16px;
  cursor: pointer;
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

#DashboardAccountMailButton {
  display: block;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #15a0d0;
  text-align: left;
  padding: 0;
  margin-top: 4px;
  width: auto;
  width: 55%;
}

#DashboardAccountMailButton:hover {
  text-decoration: underline;
}

#AccountSendMail-Popup {
  padding: 40px;
}

#AccountSendMail-Popup h1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
}

#AccountSendMail-Popup p {
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #2e2e2e;
  margin-top: 16px;
}

#AccountSendMail-Popup button {
  margin-top: 32px;
  padding: 10px 50px;
}

.UIInputBlockError-Popup {
  position: absolute;
  z-index: 2;
  background: rgba(209, 213, 217, 0.8);
  border-radius: 3px;
  padding: 5px 8px;
  display: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 130%;
  height: 30px;
  width: 85px;
  text-align: left;
  margin-top: 75px;
  margin-left: -60px;
}

.UITableError-Popup {
  position: absolute;
  z-index: 2;
  background: rgba(209, 213, 217, 0.8);
  border-radius: 3px;
  padding: 5px 8px;
  display: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 130%;
  user-select: none;
  min-height: 30px;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-left: 0px;
}

.UITable-ErrorIcon,
.UIInputBlock-ErrorIcon {
  cursor: help !important;
}

.UIInputBlock-ErrorIcon:hover .UIInputBlockError-Popup {
  display: block;
}

.UITable-ErrorIcon:hover .UITableError-Popup {
  display: flex;
}

#DashboardHeader {
  position: absolute;
  top: 0;
  margin: 0 0 0 -165px;
  z-index: 10;
  height: 100px;
  display: flex;
  align-items: center;
  width: 1280px;
  justify-content: space-between;
}

.DashboardHeaderProfileButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #2e2e2e;
  background-color: transparent;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
}

.DashboardHeaderProfileButton:hover {
  background-color: #e9f2f5;
}

.DashboardHeaderProfileButton:active {
  background-color: #d2e4eb;
}

.DashboardHeaderProfileButton-Open {
  background: #fcfdfd;
  border-radius: 8px;
  margin-top: 48px;
}

.DashboardHeaderProfileButton-Open button {
  width: 100%;
}

.DashboardHeaderProfileButton-Open a {
  text-decoration: none;
}

.DashboardHeaderProfileButton svg {
  margin: 0 16px 0 12px;
}

.DashboardTable-Group {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #434a54;
  text-align: left;
}

.DashboardTable-Group th {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: #2e2e2e;
}

.DashboardTable-Group button {
  background-color: transparent;
  border: none;
  color: #15a0d0;
  cursor: pointer;
}

.DashboardTable-Group button:hover {
  text-decoration: underline;
}

.DashboardTable-Group td,
.DashboardTable-Group th {
  padding: 11px 14px;
}

.SectionResults {
  margin: 50px 0 43px;
}

.SectionResults h3 {
  margin: 0 0 40px;
}

.SectionResults canvas {
  margin: -245px auto 0;
  z-index: 50;
  position: absolute;
}

.SectionResults .pie-Chart-Desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0 29px;
}

.SectionResults .pieChart-bg-Desktop {
  width: 240px;
  height: 240px;
  margin: 0 auto 0;
}

.SectionResults .pieChart-bg-Desktop svg {
  z-index: 2;
}

.SectionResults .pieChart-score-Desktop {
  position: absolute;
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  text-transform: uppercase;
  color: #434a54;
}

.barChart-Desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.DeaktopBarChart-row {
  display: flex;
  align-items: center;
}

.DeaktopBarChartBar-label {
  width: 150px;
  text-align: left;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #434a54;
  margin-right: 20px;
}

.DeaktopBarChart-bar {
  width: 450px;
  height: 38px;
  margin: 18px 0;
}

.DeaktopBarChartBar-bg {
  width: 100%;
  background: #e6e9ed;
  height: 36px;
}

.DeaktopBarChartBar-fg {
  background: #a0d468;
  height: 36px;
  margin-top: -36px;
  display: flex;
  align-items: center;
  z-index: 5;
  flex-direction: row;
}

.DeaktopBarChartBar-score {
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  width: 100%;
  text-align: right;
  min-width: 45px;
  margin-right: 15px;
  z-index: 10;
}

.DeaktopBarChartBar-score-dark {
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #434a54;
  width: 100%;
  text-align: right;
  min-width: 45px;
  margin-right: 15px;
  z-index: 10;
}

.pieChart-SpanBox-Desktop {
  position: absolute;
  display: flex;
}

.pieChartSpanBox-Block-Desktop {
  display: flex;
  flex-direction: column;
  margin: 0 4px;
}

.pieChart-time-Desktop {
  font-family: "PT Sans Caption";
  font-size: 36px;
  font-weight: 700;
  color: #434a54;
  letter-spacing: -3px;
}

.pieChart-TimeSubtitle-Desktop {
  font-family: "PT Sans Caption";
  font-size: 11px;
  font-weight: 700;
  color: #434a54;
  margin-top: -3px;
}

.levelstats-box-Desktop {
  max-width: 500px;
  margin: 0 auto;
}

.Desktop-levelstats-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
}

.Desktop-levelstats-colorblock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Desktop-levelstats-color {
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#DashboardResults-Recommendation {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
  text-align: left;
  width: 600px;
  margin: 0 auto;
}

#DashboardResults-RecommendationText {
  width: 600px;
  text-align: left;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #2e2e2e;
  margin: 24px auto;
}

.Dashboard-breadcrumbs {
  display: flex;
  position: absolute;
  top: 0;
  height: 100px;
  align-items: center;
  z-index: 100;
}

.Dashboard-breadcrumbs button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #15a0d0;
  margin: 0;
  padding: 0;
}

.Dashboard-breadcrumbs button:hover {
  text-decoration: underline;
}

.Dashboard-breadcrumbs figure {
  margin: 0 16px;
  display: flex;
  justify-items: center;
  align-items: center;
}

#DashboardTableGroups {
}

.DashboardGroupsIdCol {
  width: 30px;
}

.DashboardGroupsGroupCol {
  width: 80px;
}

.DashboardGroupsEditCol {
  width: 30px;
}

.DashboardGroupsDelCol {
  width: 30px;
}

.DashboardGroupsGroupCol .UIInputBlockBox {
  width: 80px !important;
}

.DashboardEditActions-Buttons {
  display: flex;
}

.DashboardEditActions-Buttons .button-green {
  padding: 14px 32px !important;
}

.DashboardEditActions-Buttons .button-green-trans {
  padding: 14px 32px;
  background: transparent;
  border-radius: 8px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-size: 20px;
  user-select: none;
  border: 1px solid rgb(160, 212, 104);
  box-sizing: border-box;
  margin-left: 24px;
}

#DashboardTableKids {
  width: 800px !important;
}

.DashboardTableKids td {
  padding: 5px 14px !important;
}

#DashboardTable-Gender {
  width: 140px;
}

#DashboardTableKids #DashboardTable-Surname {
  max-width: 60px !important;
}

/* .DashboardTableKids {
  width: auto !important;
} */

.DashboardKidName .UIInputBlockBox {
  width: 80px !important;
}

.DashboardKidSecondName .UIInputBlockBox {
  width: 140px !important;
}

.DashboardKidGroup {
  max-width: 70px;
}

.DashboardKidGroup .UIInputBlock {
  width: 70px !important;
}

.DashboardKidGenderSelect {
  width: 100px !important;
  padding: 8px !important;
  height: 36px !important;
  margin: -1px 0 0 !important;
}

.Kids-inviteCard .UIInputBlockBox {
  margin: 8px 0 24px !important;
}

.DashboardTableKids td {
  height: 58px !important;
  box-sizing: border-box;
}

.DashboardTableKids tr {
  height: 58px !important;
  box-sizing: border-box;
}

.DashboardQuiz-Box {
  width: 600px;
}

.DashboardQuiz-Box h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #2e2e2e;
  text-align: left;
  margin-top: 32px;
}

.DashboardQuiz-Box img {
  display: block;
  width: 600px;
}

.DashboardAccountAvatarDelete-Button:hover {
  text-decoration: underline;
}

.plusIcon {
  display: flex;
  align-items: center;
}

aside button:hover {
  background-color: #a4c9d680 !important;
}

aside button:active {
  background-color: #4fc1e9cc !important;
}

aside button:active div {
  filter: none !important ;
}
/* Микрофиксы вне ТЗ*/

@media (max-width: 1280px) and (orientation: landscape) {
  .header {
    padding: 0 40px !important;
  }
}

@media (max-width: 1200px) and (orientation: landscape) {
  .container {
    max-width: 100% !important;
  }

  .header {
    padding-top: 5vw !important;
  }

  .mainpage-cards-box {
    width: 90%;
  }

  .mainpage-card {
    width: 25vw;
  }
}

.main {
  padding-bottom: 0;
}

#selectrole-box {
  height: calc(100vh - 100px);
}

/* МОБИЛЬНАЯ ВЕРСИЯ */

@media (orientation: portrait), (max-width: 1200px) {
  .container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .header {
    padding: 6vw 8vw 0;
    align-items: flex-start;
  }

  #header_img,
  #footer-logo-mobile img {
    width: 30vw !important;
  }

  .headerButton {
    padding: 0;
  }

  .headerButton:hover,
  .headerButton:active {
    background-color: transparent;
  }

  .headerButton div {
    margin: 0 !important;
  }

  .headerButton span {
    display: none;
  }

  .headerButton svg {
    width: 4.5vw;
    height: auto;
  }

  .pt_header {
    padding-top: 15vw;
  }

  .topSection {
    display: none;
  }

  .topSection-mobile {
    display: block !important;
  }

  .topSection-mobile-planet {
    width: calc(100vw - 23.56vw);
    margin: 7vw auto 0;
  }

  .topSection-mobile a {
    width: 100%;
  }

  .topLabel-mobile {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 11vw;
    line-height: 130%;
    /* or 52px */

    letter-spacing: -0.01em;
    text-transform: uppercase;

    color: #1b5cbe;
    margin: 8.8vw 0 4.4vw;
  }

  .descriptionText-mobile {
    font-family: "PT Sans Caption";
    font-style: normal;
    font-weight: 400;
    font-size: 4.3vw;
    line-height: 160%;
    /* or 26px */

    letter-spacing: -0.01em;

    color: #9794b3;
    margin: 4.4vw 0 8.8vw;
  }

  section {
    padding: 0 7vw;
  }

  .footer-box {
    align-items: flex-start;
    flex-direction: column-reverse;
    background: #f5f7fa;
    padding: 5.5vw 7.4vw;
    font-size: 4.4vw;
  }

  .footer-left {
    flex-direction: column;
    align-items: start;
    margin: 0;
  }

  .footer-left a {
    font-size: 4.4vw;
    margin-bottom: 4.4vw;
  }

  .footer-social-box {
    display: none;
  }

  #footer_img {
    display: none;
  }
  #footer-privacy {
    margin: 0 0 4.4vw !important;
  }

  #footer-logo-mobile {
    display: block !important;
  }

  #footer-logo-mobile img {
    width: 29.6vw;
  }

  #footer-social-box-mobile {
    display: flex !important;
    margin: 6.3vw 0 4.4vw;
    align-items: center;
  }

  #footer-social-box-mobile svg {
    width: 6.6vw !important;
    height: 6.6vw !important;
    margin-right: 7vw;
  }

  .footer-tel {
    font-size: 4.4vw;
    margin-bottom: 4.4vw;
  }

  .footer-email {
    font-size: 4.4vw;
  }

  #mainpage-section-code {
    display: none;
  }

  #select_education,
  #registration_button,
  .placeholder-fix-home-page {
    height: auto !important;
    width: 100% !important;
    padding: 4.4vw 8.8vw;
    margin: 0 0 4.4vw !important;
    font-size: 4.4vw !important;
    line-height: 5.5vw !important;
    border-radius: 2.2vw !important;
    font-size: 4vw !important;
    line-height: 6.6vw !important;
    box-sizing: border-box;
  }

  #select_education {
    border: solid 1px rgb(110, 175, 250) !important;
  }

  #registration_button {
    border: solid 1px rgb(57, 204, 92) !important;
  }

  .button-green-index {
    border: solid 1px rgb(160, 212, 104) !important;
  }

  .placeholder-fix-home-page {
    padding: 3.3vw 8.8vw !important;
    text-align: center;
  }

  #mainpage-section-our-target h2 {
    text-align: left !important;
    font-family: "PT Sans Caption" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 6.6vw !important;
    line-height: 160% !important;
  }

  #mainpage-section-our-target p {
    width: 100% !important;
    text-align: left !important;
    font-size: 4.4vw !important;
    line-height: 120% !important;
  }

  .mainpage-cards-box {
    flex-direction: column;
    margin: 8.8vw 0 0 0;
    width: 100% !important;
  }

  .mainpage-card {
    width: 100% !important;
    flex-direction: row;
    padding: 6.6vw 4.4vw;
    box-sizing: border-box;
    justify-content: flex-start;
    margin-bottom: 4.4vw;
    border-radius: 4.4vw !important;
  }

  .mainpage-card svg {
    margin-right: 4.4vw;
    width: 12.33vw;
    height: 13.42vw;
  }

  .mainpage-card h4 {
    font-size: 4.4vw;
    line-height: 6.6vw;
    text-align: left;
    margin: 0 0 2.2vw;
  }

  .mainpage-card span {
    font-size: 3.83vw;
    line-height: 120%;
    text-align: left;
  }

  .mainpage-game-header {
    margin: 8.8vw 0 6.6vw;
  }

  .mainpage-how-useful {
    background: transparent;
    padding: 0 6.6vw !important;
  }

  .mainpage-how-useful img {
    display: none;
  }

  .mainpage-how-useful h4 {
    display: none;
  }

  .mainpage-how-useful-block {
  }

  .mainpage-how-useful-block-row svg {
    width: 7vw !important;
    height: 6.6vw !important;
  }

  .mainpage-how-useful-block-row p {
    width: 100%;
    font-size: 4.4vw;
    line-height: 120%;
    margin: 0 0 4.4vw 8.8vw;
  }

  #additional-registration_button-mobile {
    display: block !important;
  }

  #additional-registration_button-mobile button {
    height: auto !important;
    width: 100% !important;
    padding: 4.4vw 8.8vw;
    font-size: 4.4vw !important;
    line-height: 5.5vw !important;
    margin: 2.2 0 10.68vw !important;
  }

  #auth-container,
  #restore-container {
    width: 100% !important;
    padding: 20vw 8vw 6vw;
    box-sizing: border-box;
  }

  #auth-container-child,
  #restore-container-child {
    width: 100%;
    position: relative !important;
  }

  #auth-container-child h1,
  #restore-container-child h1 {
    font-size: 6.6vw !important;
    line-height: 7.9vw !important;
  }

  #auth-container-child .UIInputBlockBox,
  #restore-container-child .UIInputBlockBox {
    width: 100% !important;
    height: 13.2vw !important;
    margin: 11vw 0 6.6vw !important;
  }

  #auth-container-child .UIInputBlockBox button,
  #restore-container-child .UIInputBlockBox button {
    width: 13.2vw !important;
    height: 13.2vw !important;
  }

  .eyeStatus-enabled {
    width: 6.3vw !important;
    height: 4.93vw !important;
  }

  .eyeStatus-disabled {
    width: 6.3vw !important;
    height: 6.4vw !important;
  }

  #auth-container-child .cbx svg {
    width: 4.93vw !important;
    height: 4.93vw !important;
  }

  #auth-container-child .cbx span {
    font-size: 3.84vw !important;
    line-height: 4vw !important;
  }

  #auth-container-child .hover-underline {
    font-size: 3.84vw !important;
  }

  #auth-container-child .button-blue,
  #restore-container-child .button-blue {
    padding: 3.29vw 15.34vw !important;
    border-radius: 2.19vw !important;
    font-size: 5.48vw !important;
    line-height: 6.58vw !important;
    margin: 11.51vw 0 0 !important;
    width: 100%;
  }

  #auth-container-child .UIInputBlock,
  #restore-container-child .UIInputBlock {
    width: 100% !important;
    height: 13.2vw !important;
    border-radius: 2.2vw !important;
    font-size: 3.84vw !important;
    padding: 2.2vw 11vw 2.2vw 4.4vw !important;
  }

  #selectrole-container {
    display: none !important;
  }

  #selectrole-container-mobile {
    display: flex !important;
    flex-direction: column !important;
  }

  .selectrole-Card {
    width: 100% !important;
    height: fit-content !important;
    border-radius: 4.38vw !important;
    padding: 6.58vw 4.38vw;
    box-sizing: border-box !important;
  }

  .selectrole-mobile-button svg {
    width: 19.73vw;
    height: 19.73vw;
  }

  #selectrole-container .selectrole-Card {
    display: flex !important;
  }

  .selectrole-mobile-button {
    background: #f5f7fa;
    border-radius: 2.19vw;
    border: none;
    padding: 6.58vw 4.38vw;
    display: flex;
    margin-bottom: 4.38vw;
  }

  .selectrole-Card-infobox {
    margin-left: 4.38vw;
    text-align: left;
  }

  .selectrole-Card-infobox span {
    font-family: "PT Sans Caption";
    font-weight: 700;
    font-size: 4.38vw;
    line-height: 6.58vw;
    letter-spacing: -0.01em;
    color: #000000;
  }

  .selectrole-Card-infobox p {
    font-family: "PT Sans Caption";
    font-weight: 400;
    font-size: 3.84vw;
    line-height: 150%;
    color: #2e2e2e;
    margin: 2.19vw 0 0;
  }

  #selectrole-box {
    height: auto;
  }

  .step_reg {
    display: none;
  }

  .main {
    min-height: 100vh;
    width: 100% !important;
    padding: 20vw 8vw 6vw;
    box-sizing: border-box;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  #NotFoundContainer {
    width: 100%;
    padding: 10vw 5vw;
    box-sizing: border-box;
  }

  #NotFoundHeader {
    font-size: 19.73vw !important;
  }

  #NotFoundSubHeader {
    margin: 4.38vw 0 0 !important;
    font-size: 6.58vw !important;
  }

  #NotFoundText {
    margin: 8.77vw 0 !important;
    font-size: 4.38vw !important;
  }

  #NotFoundButtonsBox {
    flex-direction: column;
  }

  #NotFoundButtonsBox button {
    margin: 0 0 4.38vw !important;
    width: 100% !important;
    font-size: 4.38vw !important;
    padding: 4.38vw 8.77vw;
    border-radius: 2.19vw;
  }

  #NotFoundButtonsBox a {
    width: 100% !important;
    margin: 0 !important;
  }

  .RegistrationFirstStepContainer,
  .RegistrationSecondStepContainer {
    flex-direction: column !important;
    width: 100% !important;
  }

  .RegistrationFirstStepAgree {
    display: none;
  }

  .RegistrationFirstStepAgree-mobile {
    display: block !important;
    font-size: 3.84vw !important;
    margin: 0 !important;
  }

  .RegistrationFirstStepMainContainer,
  .RegistrationSecondStepMainContainer {
    left: initial !important;
    top: initial !important;
    position: relative !important;
    transform: initial !important;
  }

  .RegistrationFirstStepMainContainer h1,
  .RegistrationSecondStepMainContainer h1 {
    font-size: 6.58vw !important;
    margin: 0 0 8.77vw !important;
  }

  .RegistrationFirstStepMainContainer .UIInputBlockBox,
  .RegistrationSecondStepMainContainer .UIInputBlockBox {
    width: 90vw !important;
    height: 13.15vw !important;
    margin: 0 0 4.38vw !important;
  }

  .RegistrationFirstStepMainContainer .UIInputBlock,
  .RegistrationSecondStepMainContainer .UIInputBlock {
    border-radius: 2.19vw !important;
    padding: 2.19vw 10.96vw 2.19vw 4vw !important;
    font-size: 3.84vw !important;
  }

  .RegistrationFirstStepMainContainer .UIInputBlockBox button,
  .RegistrationSecondStepMainContainer .UIInputBlock button {
    height: 13.15vw !important;
    width: 13.15vw !important;
  }

  .RegistrationFirstStepMainContainer .button-blue,
  .RegistrationSecondStepMainContainer .button-blue,
  #EduNotFound-Container .button-blue,
  #DirectorEduStep-Container .button-blue {
    margin: 4.38vw 0 2.19vw !important;
    width: 100% !important;
    font-size: 5.48vw !important;
    border-radius: 2.19vw !important;
    padding: 3.84vw 11vw !important;
  }

  #EduNotFound-Container,
  #DirectorEduStep-Container {
    position: relative !important;
    left: initial !important;
    top: initial !important;
    transform: none !important;
    display: block;
    max-width: 100%;
  }

  #EduNotFound-Container h1,
  #DirectorEduStep-Container h1 {
    font-size: 6.58vw !important;
    margin: 0 0 8.77vw !important;
  }

  .EduNotFound-Input,
  .DirectorEduStep-Input {
    width: 100% !important;
    margin: 0px auto;
  }

  .EduNotFound-Input .UIInputBlockBox,
  .DirectorEduStep-Input .UIInputBlockBox,
  .DirectorEduStep-Input select {
    width: 100% !important;
    height: 13.15vw !important;
    margin: 0 0 4.38vw !important;
  }

  .EduNotFound-Input .UIInputBlockBox input,
  .DirectorEduStep-Input .UIInputBlockBox input,
  .DirectorEduStep-Input select {
    border-radius: 2.19vw !important;
    padding: 4.38vw !important;
    font-size: 3.83vw !important;
    line-height: 4.93vw !important;
    color: #79797a !important;
  }

  #EduNotFound-Container p {
    display: none;
  }

  #DirectorEduStep-Container p {
    margin: -2.19vw 0 8.76vw !important;
    font-size: 3.83vw;
    line-height: 6.57vw;
  }

  #DirectorEduStep-Desktop {
    display: none;
  }

  #DirectorEduStep-Mobile {
    display: block;
  }

  #NotFoundButtonsBox .button-green,
  #NotFoundButtonsBox .button-green-trans {
    border-radius: 2.19vw !important;
    padding: 4.38vw 8.76vw !important;
  }

  .cbx svg {
    margin: 0.5vw 3vw 0px 0px !important;
  }
  #restore-container-child .UIInputBlockBox {
    margin: 8.77vw 0 6.58vw !important;
  }

  #restore-container-child .button-blue {
    margin-top: 0 !important;
  }

  .popup-bg {
    z-index: 0;
  }

  .popup-card {
    border-radius: 0;
    height: 100vh;
    width: 100vw;
    justify-content: center;
  }

  .popup-card h1 {
    display: block !important;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 6.58vw;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #000000;
    margin: 0 0 4.38vw;
  }

  .popup-card p {
    margin: 0;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 4.38vw;
    line-height: 160%;
    text-align: center;
    color: #2e2e2e;
  }

  #restore-popup-button-bg {
    width: 100%;
    padding: 3.29vw 15.34vw !important;
    border-radius: 2.19vw !important;
    font-size: 5.48vw !important;
    line-height: 6.58vw !important;
    margin: 8.77vw 0 0 !important;
    width: 84vw;
  }

  #NotFoundButtonsBox .button-green,
  #NotFoundButtonsBox .button-green-trans {
    border-radius: 2.19vw !important;
    padding: 4.38vw 8.76vw !important;
  }

  .RegistrationTeacherCode-CardBox {
    margin-top: 10vw;
    flex-direction: column;
  }

  .RegistrationTeacherCodeCardBox-Card {
    margin: 0 0 6.57vw;
    width: 75vw;
    height: auto;
    border-radius: 4.38vw;
    padding: 5.47vw;
  }

  .RegistrationTeacherCodeCardBoxCard-Heading {
    font-family: "PT Sans Caption";
    font-style: normal;
    font-weight: 700;
    font-size: 4.38vw;
    line-height: 6.57vw;
    text-align: left;
    letter-spacing: -0.01em;
    color: #000000;
    width: 75vw;
    margin-bottom: 4.38vw;
  }

  .RegistrationTeacherCodeCardBoxCard-Summary {
    font-size: 4.38vw;
    line-height: 6.57vw;
  }

  .RegistrationTeacherCodeCardBoxCard-Subflex .UIInputBlockBox {
    width: 75vw !important;
    height: 13.15vw !important;
    margin: 4.38vw 0 0 !important;
  }

  .RegistrationTeacherCodeCardBox-Card .button-blue {
    margin: 4.38vw 0 0 !important;
  }

  #TeacherCode-Popup,
  #ParentCode-Popup {
    padding: 6.57vw;
  }

  #TeacherCode-Popup h1,
  #ParentCode-Popup h1 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 6.57vw;
    line-height: 160%;
    text-align: center;
    color: #2e2e2e;
    margin-bottom: 2.19vw;
  }

  #TeacherCode-Popup p,
  #ParentCode-Popup p {
    font-family: "PT Sans Caption";
    font-style: normal;
    font-weight: 400;
    font-size: 4.38vw;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #2e2e2e;
  }

  #TeacherCodePopupButton-Desktop {
    display: none;
  }

  #TeacherCodePopupButton-Mobile {
    display: block;
  }

  #TeacherCodePopupButton-Mobile .button-green {
    width: 90vw;
  }

  .OnlyMobile {
    display: block;
  }
  .OnlyDesktop {
    display: none;
  }

  #ParentNameGenderSelect {
    border-radius: 2.19vw !important;
    padding: 4.38vw !important;
    font-size: 3.83vw !important;
    line-height: 4.93vw !important;
    color: #79797a !important;
    width: 100% !important;
    height: 13.15vw !important;
    margin: 0 0 4.38vw !important;
  }

  #restore-container .UIInputBlockBox {
    margin-top: 0 !important;
  }

  #restore-container h1 {
    margin-bottom: 8.76vw !important;
  }
}
