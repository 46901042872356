.wrap{
    margin-top: 48px;
    padding: 0 15px 15px;
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px #d1d4d9;
    border-top: solid 1px #d1d4d9;
}

.header{
    text-transform: uppercase;
}

.text{
    font-family: 'PT Sans Caption', sans-serif;
    line-height: 1.615;
    margin: 0 0 24px;
}