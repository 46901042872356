.a{
    height: 36px;
    background: #E6E9ED;
    display: flex;
    align-items: flex-end;
    border-left: 2px solid rgba(67, 74, 84, 0.12);
    position: relative;
}

.b{
    display: flex;
    justify-content: flex-end;
    background: #A0D468;
    height: 100%;
}

.lineGame{
    width: 2px;
    height: 20px;
    background: rgba(67, 74, 84, 0.2);
    position: relative;
}

.lineGame:nth-child(3):after {
    display: block;
    width: 5px;
    height: 2px;
    content: " ";
    background: rgba(67, 74, 84, 0.2);
    position: absolute;
    left: 50%;
    transform: translateX(-100%);
    bottom: -1px;
}
.lineGame_first:after {
    display: block;
    width: 5px;
    height: 2px;
    content: " ";
    background: rgba(67, 74, 84, 0.2);
    position: absolute;
    left: 50%;
    transform: translate(-100%, -1000%);
    bottom: -1px;
}

.percent{
    margin: auto 0;
    color: #434A54;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.percent_right{
    padding-right: 9px;
    right: 0;
}
.percent_left{
    padding-left: 9px;
}

.name{
    display: none;
}


@media screen and (max-width: 768.99px){
    .percent_left{
        padding-left: none;
        padding-right: 9px;
        right: 0;
    }
    .name{
        display: block;
        margin: auto 0px;
        position: absolute;
        color: rgb(67, 74, 84);
        top: 25%;
        left: 5%;
    }
    .lineGame{
        display: none;
    }
    .a{
        margin-top: 12px;
    }
}