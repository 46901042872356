.topSection {
  height: 580px;
  width: 100%;
  background: #f5f7fa;
}

.topLabel {
  width: 492px;
  height: 192px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 96px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #1b5cbe;
  text-align: start;
  margin: 71px 0 37px;
}

.descriptionText {
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 160%;
  letter-spacing: -0.01em;

  color: #9794b3;

  text-align: start;

  margin: 37px 0;
}

#internal {
  width: 1082px;
  margin: auto;
}

.mainpage-cards-box {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 90px auto 0;
}

.mainpage-card {
  width: 360px;
  background: #f5f7fa;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

.mainpage-card h4 {
  margin: 23px 0 18.55px;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
}

.mainpage-card span {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
  width: 100%;
  display: block;
}

.mainpage-game-header {
  margin: 97.5px 0 47.65px;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #1b5cbe;
}

.mainpage-how-useful {
  padding: 41px 0 0;
  background: #f5f7fa;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
}

.mainpage-how-useful img {
  margin-right: 117px;
}

.mainpage-how-useful h4 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #615ba3;
  margin: 0 0 39px;
}

.mainpage-how-useful-block-row {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
}

.mainpage-how-useful-block-row p {
  width: 548px;
  margin: 0 0 28px 35px;
  font-family: "PT Sans Caption";

  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #9794b2;
}
