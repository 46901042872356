#DashboardMobile-Header {
  padding: 6vw 8vw;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
}

#DashboardMobile-Logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DashboardMobile-Button {
  padding: 3vw !important;
  margin: -3vw -3vw;
  background-color: transparent;
  border: none;
  -webkit-tap-highlight-color: transparent;
}

.DashboardMobile-Button:hover {
  background-color: rgba(210, 228, 235, 0.5) !important;
}

.DashboardMobile-Button:active {
  background-color: rgba(114, 205, 237, 1) !important;
}

#DashboardMobile-MenuContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(15, 54, 68, 0.6);
}

#DashboardMobile-MenuCard {
  background-color: #ffffff;
  border-radius: 2.19vw 2.19vw 0 0;
  position: fixed;
  bottom: -1px;
  left: 0;
  width: 100%;
}

#DashboardMobile-MenuCard figure svg {
  height: 3vw !important;
}

.DashboardAsideButtonsMobile button {
  font-size: 4.38vw;
  -webkit-tap-highlight-color: transparent;
}

.DashboardAsideButtonsMobile button div {
  margin-right: 2.74vw !important;
}

.DashboardAsideButtonsMobile button div svg {
  height: 4.38vw !important;
  width: 4.38vw !important;
}

.DashboardAsideButtonsMobile button span {
  margin: 0;
}

#DashboardMobile-MenuCard button:hover {
  background: rgba(210, 228, 235, 0.5) !important;
}

#DashboardMobile-MenuCard button:active {
  background: rgba(210, 228, 235, 0.5) !important;
}

.DashboardMobile-MenuElement {
  cursor: pointer;
  background: transparent;
  width: 100%;
  height: 13.15vw;
  padding: 3.29vw 6.58vw;
  border-radius: 2.19vw;
  --webkit-user-select: none;
  --ms-user-select: none;
  user-select: none;
  border: none;
  display: flex;
  align-items: center;
}

.DashboardMobile-MenuElementFlex {
  display: "flex";
  align-items: center;
  justify-content: center;
  margin-right: 2.74vw;
}

.DashboardMobile-Content {
  width: 100%;
  min-height: 80vh;
  top: 0;
  box-sizing: border-box;
}

.DashboardMobile-Menu {
  display: flex;
  flex-direction: column;
}

.DashboardMobile-Menu button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3.29vw 7.12vw;
  background-color: transparent;
  border: none;
  border-radius: 2.19vw;
  font-size: 4.38vw;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

.DashboardMobile-Menu button:hover {
  background-color: rgba(210, 228, 235, 0.5);
}

.DashboardMobile-Menu button:active {
  background-color: rgba(114, 205, 237, 1);
}

.DashboardMobile-MenuElementFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DashboardMobile-MenuElementFlex svg {
  height: 4.38vw !important;
  width: 4.38vw !important;
}

.DashboardMobile-h1 {
  margin: 0 0 8.77vw;
  text-align: center;
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 6.6vw;
  line-height: 7.9vw;
  letter-spacing: -0.01em;
  color: rgb(0, 0, 0);
}

.DashboardMobile-p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 4.38vw;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #2e2e2e;
}

.DashboardMobile-Content .button-blue,
.DashboardMobile-Content .button-green {
  width: 100%;
  margin: 4.38vw 0 2.19vw;
  -webkit-tap-highlight-color: transparent;

  width: 100% !important;
  font-size: 5.48vw !important;
  border-radius: 2.19vw !important;
  padding: 3.84vw 11vw !important;
}

.MobileTeachersBlock-Card,
.MobileKidsBlock-Card {
  display: flex;
  flex-direction: column;
  margin-bottom: 4.38vw;
}

.MobileTeachersBlock-Card h3,
.MobileKidsBlock-Card h3 {
  text-align: left;
  margin: 0 0 2.19vw;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 4.38vw;
  line-height: 120%;
  color: #2e2e2e;
}

.MobileTeachersBlock-Groups,
.MobileKidsBlock-Groups {
  margin: 0 0 2.19vw;
  text-align: left;
  font-size: 3.84vw;
  line-height: 130%;
  color: #2e2e2e;
}

.teacherStatus-block,
.kidStatus-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 4vw;
}

.teacherStatus-block svg,
.kidStatus-block svg {
  width: 3.84vw !important;
  height: 3.84vw !important;
  margin-right: 2.74vw;
}

.teacherStatus-block span,
.kidStatus-block span {
  font-size: 2.19vw;
  text-align: left;
}

.teacherStatusBlock-actions,
.kidStatusBlock-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.19vw;
}

.teacherStatusBlock-left,
.kidStatusBlock-left {
  width: 25vw;
  min-height: 6.58vw;
}

.teacherStatusBlock-icons,
.kidStatusBlock-icons {
  display: flex;
  flex-direction: row;
}

.teacherStatusBlock-icons button,
.kidStatusBlock-icons button {
  background-color: transparent;
  border: none;
  padding: 1vw;
  border-radius: 1vw;
  width: 7.67vw;
  height: 7.67vw;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
}

.teacherStatusBlock-icons button svg,
.kidStatusBlock-icons button svg {
  width: 4.93vw;
  height: 4.93vw;
}

.teacherStatusBlock-icons button:hover,
.kidStatusBlock-icons button:hover {
  background-color: rgba(210, 228, 235, 0.5);
}

.teacherStatusBlock-icons button:active,
.kidStatusBlock-icons button:hover {
  background-color: rgba(114, 205, 237, 1);
}

.teacherStatusBlock-invite,
.kidStatusBlock-invite {
  background-color: transparent;
  border: none;
  padding: 1vw 1vw 1vw 0;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.84vw;
  color: rgba(21, 160, 208, 1);
}

.teacherStatusBlock-invite:hover,
.teacherStatusBlock-invite:active,
.kidStatusBlock-invite:hover,
.kidStatusBlock-invite:active {
  text-decoration: underline;
}

.teacherEditBlock,
.kidEditBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.teacherEditBlock span,
.kidEditBlock span {
  font-size: 3.84vw;
  line-height: 130%;
  color: #434a54;
  text-align: left;
}

.teacherEditBlock .UIInputBlockBox,
.kidEditBlock .UIInputBlockBox {
  margin: 2.19vw 0 !important;
}

.teacherEditBlock .UIInputBlock,
.MobileTeachers-inviteCard .UIInputBlock,
.DashboardMobile-Security .UIInputBlock,
.kidEditBlock .UIInputBlock,
.MobileKids-inviteCard .UIInputBlock {
  padding: 3vw 4vw !important;
  border-radius: 2.19vw !important;
  color: #434a54 !important;
  font-size: 3.84vw !important;
  line-height: 130% !important;
}

.teacherEditBlock .button-green,
.kidEditBlock {
  box-sizing: border-box;
  background: #a0d468;
  font-family: "PT Sans Caption";
  color: #000000;
  border: 1px solid #a0d468;
  cursor: pointer;
  width: 100% !important;
  font-size: 5.48vw !important;
  border-radius: 2.19vw !important;
  padding: 3.84vw 11vw !important;
  -webkit-tap-highlight-color: transparent;
  margin-bottom: 4.38vw;
}
.teacherEditBlock .button-green-trans,
.kidEditBlock .button-green-trans {
  box-sizing: border-box;
  background: transparent;
  font-family: "PT Sans Caption";
  color: #000000;
  border: 1px solid #a0d468;
  cursor: pointer;
  width: 100% !important;
  font-size: 5.48vw !important;
  border-radius: 2.19vw !important;
  padding: 3.84vw 11vw !important;
  -webkit-tap-highlight-color: transparent;
  margin-bottom: 8.77vw;
}

.MobileTeachers-PopupBG,
.MobileKids-PopupBG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(15, 54, 68, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileTeachers-inviteCard,
.MobileTeachers-deleteCard,
.MobileKids-inviteCard,
.MobileKids-deleteCard {
  background: #f5f7fa;
  border-radius: 4.38vw;
  z-index: 15;
  padding: 5.48vw;
  width: 80vw;
}

.MobileTeachersinviteCard__buttonContainer,
.MobileKidsinviteCard__buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.MobileTeachersinviteCard__buttonContainer button,
.MobileKidsinviteCard__buttonContainer button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
}

.MobileTeachersinviteCard__buttonContainer button svg,
.MobileKidsinviteCard__buttonContainer button svg {
  width: 5.2vw;
  height: 5.2vw;
}

.MobileTeachers-inviteCard h3,
.MobileKids-inviteCard h3 {
  margin: 2.19vw 0 4.38vw;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 4.38vw;
  line-height: 130%;
  text-align: center;
  color: #000000;
}

.MobileTeachers-inviteCard p,
.MobileKids-inviteCard p {
  margin: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 4.38vw;
  line-height: 150%;
  text-align: center;
  color: #000000;
}

.MobileTeachers-inviteCode,
.MobileKids-inviteCode {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 6.58vw;
  line-height: 160%;
  text-align: center;
  color: #000000;
  margin: 6.58vw 0;
  display: block;
}

.MobileTeachers-inviteCard .UIInputBlockBox,
.MobileKids-inviteCard .UIInputBlockBox {
  margin: 6.58vw 0 4.38vw !important;
}

.MobileTeachers-inviteCard .button-blue,
.MobileKids-inviteCard .button-blue {
  margin-top: 0 !important;
  -webkit-tap-highlight-color: transparent;
}

.MobileTeachers-deleteCard h3,
.MobileKids-deleteCard h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 4.38vw;
  line-height: 130%;
  text-align: center;
  color: #000000;
  margin-top: 0;
}

.MobileKids-deleteCard p,
.MobileTeachers-deleteCard p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 4.38vw;
  line-height: 130%;
  text-align: center;
  color: #000000;
}

.MobileTeachersdeleteCard__actions,
.MobileKidsdeleteCard__actions {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.MobileTeachersdeleteCard__actions button,
.MobileKidsdeleteCard__actions button {
  width: 100%;
  padding: 3.29vw 6.6vw;
  margin: 0 3vw;
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 400;
  font-size: 5.48vw;
  line-height: 6.58vw;
  border-radius: 2.19vw;
  border: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.MobileTeachersdeleteCard__actions button:hover,
.MobileKidsdeleteCard__actions button:hover {
  background-color: rgba(210, 228, 235, 0.5) !important;
}

.MobileTeachersdeleteCard__actions button:active,
.MobileKidsdeleteCard__actions button:active {
  background-color: rgba(114, 205, 237, 1) !important;
}

.DashboardMobile-Security .UIInputBlockBox button {
  height: 13.15vw !important;
  width: 13.15vw !important;
}

.DashboardMobile-AvatarButton {
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 8.76vw;
  -webkit-tap-highlight-color: transparent;
}

.DashboardMobile-AvatarButton img {
  width: 26.3vw;
  height: 26.3vw;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.DashboardMobile-Security .button-green {
  margin-top: 8.76vw;
}

.DashboardAsideButtonsMobile a {
  text-decoration: none;
}

.DashboardMobile-Account h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 4.38vw;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #2e2e2e;
  text-align: left;
  margin-top: 8.76vw;
}

.DashboardMobile-Account span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 4.38vw;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #2e2e2e;
  text-align: left;
  display: block;
  margin: 4.38vw 0 2.19vw;
}

.DashboardMobile-Account .UIInputBlockBox button {
  -webkit-tap-highlight-color: transparent;
}

.DashboardMobile-Account .UIInputBlockBox button svg {
  height: 5.47vw !important;
  width: 5.47vw !important;
}

.DashboardMobile-Account .span-error {
  color: #fe356b;
  margin: 1.09vw 0 0;
}

.DashboardMobile-Account .span-success {
  color: #00bc40;
  margin: 1.09vw 0 0;
}

#sendMail-span {
  color: #15a0d0;
  background: transparent;
  border: none;
  font-size: 3.83vw;
  line-height: 6.57vw;
  display: block;
  margin: 0;
  padding: 0;
}

.DashboardMobile-Account .button-blue {
  margin-top: 8.76vw;
}

.DashboardMobile-Account .MobileTeachers-inviteCard h3 {
  font-size: 6.57vw;
}

.DashboardMobile-Account .MobileTeachers-inviteCard .button-blue {
  margin-top: 4.38vw !important;
}

.DashboardMobile-Quiz h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 4.38vw;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #2e2e2e;
  text-align: left;
  margin-top: 8.76vw;
}

.DashboardMobile-Quiz img {
  max-width: 100%;
}

.radioBox {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.radioBox input {
  width: 5.47vw;
  height: 5.47vw;
  margin: 4.38vw;
}

.MobileKids-inviteCard .span-error {
  display: block;
  color: #fe356b;
  margin: -3vw 0 4.38vw;
  text-align: left;
  font-weight: 700;
}

#MobileKidsInviteStatus--Text {
  margin-top: 5.48vw;
  display: block;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 6.57vw;
  line-height: 160%;
  color: #2e2e2e;
}

#MobileKidsInviteStatus--Success {
  margin: 4.38vw 0 5.48vw;
}

#MobileKidsInviteStatus--Success svg {
  height: 15.34vw;
  width: 15.34vw;
}

.DashboardMobileQuiz-SelectKid p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 4.38vw;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #2e2e2e;
  text-align: left;
  margin-bottom: 8.76vw;
}

.DashboardMobileQuiz-SelectKid h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 4.38vw;
  line-height: 120%;
  text-align: left;
  letter-spacing: -0.01em;
  color: #2e2e2e;
}

.DashboardMobileQuizSelectKid-Card {
  margin-bottom: 8.76vw;
}

#DashboardMobileResults h1 {
  margin: 0 0 8.76vw 0;
}

.SectionMobileResults {
}

.SectionMobileResults h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 3.28vw;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #434a54;
  margin: 10.95vw 0 8.21vw -10vw;
  width: 90vw;
}
.pie-Chart {
  display: flex;
}

.pie-Chart canvas {
  width: 70vw !important;
  height: 70vw !important;
}

.pie-Chart figure {
  margin: 0;
}

.pie-Chart canvas {
  position: absolute;
}

.pieChart-bg svg {
  width: 70vw;
  height: 70vw;
}

.pieChart-score {
  position: absolute;
  font-size: 12vw;
  width: 70vw;
  height: 70vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -4vw;
  font-weight: 700;
  color: #434a54;
  font-family: "PT Sans Caption";
}

.pieChart-subtitle {
  position: absolute;
  font-size: 4vw;
  width: 70vw;
  height: 70vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4vw;
  font-weight: 700;
  color: #434a54;
  font-family: "PT Sans Caption";
}

.barChart {
}

.barChart-bar {
  display: flex;
  width: 70vw;
  margin-bottom: 4.93vw;
}

.barChartBar-bg {
  background-color: #e6e9ed;
  height: 9.86vw;
  width: 70vw;
  position: absolute;
  z-index: 1;
}

.barChartBar-fg {
  background-color: #a0d468;
  height: 9.86vw;
  z-index: 2;
}

.barChartBar-label {
  z-index: 3;
  position: absolute;
  height: 9.86vw;
  display: flex;
  align-items: center;
  margin-left: 2.19vw;
  color: #434a54;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 3.83vw;
}

.barChartBar-score {
  z-index: 3;
  position: absolute;
  height: 9.86vw;
  width: 62.88vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #ffffff;
  font-family: "PT Sans Caption";
  font-style: normal;
  font-weight: 700;
  font-size: 3.28vw;
}

.pieChart-SpanBox {
  position: absolute;
  width: 70vw;
  height: 70vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pieChartSpanBox-Block {
  display: flex;
  flex-direction: column;
  margin: 1vw;
}

.pieChart-time {
  font-family: "PT Sans Caption";
  font-size: 10vw;
  font-weight: 700;
  color: #434a54;
  letter-spacing: -0.5vw;
}

.pieChart-TimeSubtitle {
  font-family: "PT Sans Caption";
  font-size: 3.25vw;
  font-weight: 700;
  color: #434a54;
  margin-top: -1vw;
}

.levelstats-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 8.76vw;
}

.levelstats-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 2.47vw 0;
}

.levelstats-color {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.levelstats-color svg {
  width: 4.93vw !important;
  height: 4.93vw !important;
  margin-right: 3.83vw;
}

.levelstats-colorblock {
  display: flex;
  flex-direction: row;
}

.levelstats-label,
.levelstats-time {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 3.83vw;
  line-height: 5.47vw;
  color: #434a54;
  text-align: left;
}

#ResultsPage-Summary {
  margin: 6.57vw 0 27.39vw;
}
